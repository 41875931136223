.navbar
	display: flex
	flex-direction: column
	place-content: center
	place-items: center
	padding-block: $gap-lg
	border-radius: unset
	background-color: transparent

.navbar-collapse
	flex-grow: unset

	.navbar-nav
		padding-block-start: $gap
		gap: $gap

.navbar-brand
	font-size: 3em
	font-family: 'Courgette', cursive

.navbar
	.navbar-nav
		gap: $gap
		font-size: 1.25em
		a
			color: $brand-secondary
			text-decoration: unset
			@include transition()
			&:after
				content: ''
				position: relative
				display: flex
				place-items: center
				height: 2px
				width: 0%
				background-color: $brand
				transition: width .3s ease-in-out, left .3s ease-in-out
				left: 50%
			&:hover
				color: $brand !important
				&:after
					width: 100%
					left: 0%