.nav .nav-link,
ul
	padding-inline: unset

ul
	margin: unset
	list-style: none

	li
		padding-block: $gap-sm
		@include transition()
		&:hover
			color: $primary

.address
	display: inline-flex
	li
		padding-block: unset