#story
	max-width: 1000px
	margin-inline: auto
	margin-block-start: $gap-xl
	display: flex
	gap: $gap-lg

	.section
		display: flex
		flex-direction: column
		place-content: center

		.section-content
			display: flex
			gap: $gap-lg

	.divider
		background-color: $brand-secondary
		min-width: 4px

	.logo
		width: 256px
		max-width: 256px