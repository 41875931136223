#contact
	display: flex
	flex-direction: column
	place-items: center
	margin-block-start: $gap-xl

	.map-container
		overflow: hidden
		width: 100%
		height: 400px
		max-width: 800px
		border-radius: .5rem
		background-color: $body-bg2

	.title
		margin-block-end: $gap-lg

	.section
		display: flex
		gap: $gap-xl
		place-items: center
		margin-block-end: $gap-lg

		.subtitle
			margin-block-end: $gap
			text-align: center
			color: $brand-secondary

		ul
			padding-inline: unset
			list-style: none

			li
				display: flex
				place-items: center
				gap: $gap
				padding-block: $gap-sm

				.icon
					display: flex
					place-items: center
					place-content: center
					background-color: transparentize($brand,.75)
					border-radius: 100%
					min-width: 48px
					min-height: 48px
					height: 48px
					width: 48px
					color: $brand

				svg
					font-size: 1.5em
					margin: unset

		.address
			display: inline-flex
			li
				padding-block: unset

		#opening-times
			background-color: $body-bg2
			padding: $gap $gap-lg
			border-radius: $border-radius
			li
				display: flex
				justify-content: space-between
				gap: $gap-lg