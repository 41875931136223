#cover
	display: flex
	flex-direction: column
	place-content: center
	max-width: 650px
	height: 750px
	padding-block-end: calc(5rem * 2)

	.title
		margin: unset
		line-height: 1em

	.subtitle
		margin-block: $gap-lg
		color: $brand-secondary

	button
		font-size: 80%
		margin-inline: auto