.accordion
	margin-block-end: $gap
	border-radius: $border-radius
	.accordion-item
		border: unset
		.accordion-button
			justify-content: space-between
			&:focus
				box-shadow: unset
			&:not(.collapsed)
				box-shadow: unset
			&:after
				content: unset
			&[aria-expanded="true"]
				.caret
					transform: rotateZ(180deg)
			.caret
				margin-inline-start: $gap-icon
				@include transition()
				transition-duration: .5s

		.accordion-button