@media only screen and (max-width: 1400px)

	#cover
		margin-inline: auto
		place-content: unset
		height: 500px
		padding-block-end: unset

		button
			margin-inline: auto
	
	#story
		margin-block-start: $gap-lg

	.cover-image
		width: 100vw
		opacity: .35
		mask-image: linear-gradient(to right, #000, #000)
		min-height: 700px
		max-height: 700px
		object-position: 50% 10%

@media only screen and (max-width: 1200px)

	#story
		flex-direction: column
		place-items: center
		.title
			text-align: center
		.divider
			display: none

	.slider
		max-width: 800px

@media only screen and (max-width: 992px)

	.title
		text-align: center
		font-size: 3em

	#cover
		max-width: 400px
		p
			text-align: center

	#story
		margin-block-start: $gap-lg

	#contact
		.title
			margin-block-end: $gap-sm

	.slider
		max-width: 400px

	footer
		.grid
			grid-template-columns: 1fr
			.section
				max-width: calc($width-content / 2)
				margin-inline: auto
				&:nth-child(2)
					order: 1
				&:first-child
					order: 2
				&:last-child
					order: 3					

@media only screen and (max-width: 768px)

	#story
		max-width: 400px
		.section
			.section-content
				flex-direction: column
				gap: $gap
				p
					margin: unset

	#contact
		margin-block-start: $gap-lg
		gap: $gap
		.section
			flex-direction: column
			gap: $gap

	#menu
		.card-menu
			grid-template-columns: 1fr

@media only screen and (max-width: 576px)

	.title
		font-size: 2em

	.navbar
		padding-block-end: $gap
		.navbar-collapse
			.navbar-nav
				@include grid(2, $gap)
				.nav-link
					display: flex
					width: fit-content
					&:after
						content: unset

	#cover
		max-width: 300px
		height: 400px

		.subtitle
			margin-block: $gap

	#story
		padding: $gap
		max-width: 300px

		.logo
			width: 128px
			max-width: 128px
	
	#contact
		.section
			#opening-times
				padding: $gap-sm $gap-lg
				li
					flex-direction: column
					gap: .25rem