.dropdown-toggle
	&[aria-expanded="true"]
		.caret
			transform: rotateZ(180deg)
	&:after
		content: unset
	.caret
		margin-inline-start: $gap-icon
		@include transition()
		transition-duration: .5s


.dropdown-menu
	display: block
	visibility: hidden
	border: unset
	opacity: 0
	@include transition()

	&.show
		visibility: visible
		opacity: 1

	.dropdown-divider
		background-color: $black-25