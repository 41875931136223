body
	height: 100vh
	//background-color: $body-bg
	background: url('../../public/images/background.svg'), $body-bg
	background-size: 300px
	background-repeat: repeat
	margin: 0
	padding: 0
	color: $body-color
	font-family: 'Lato', sans-serif
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale
	scroll-behavior: smooth

.cover-image
	position: absolute
	top: 0
	right: 0
	z-index: -1
	width: 50vw
	min-height: 900px
	mask-image: linear-gradient(to right, #fff0 0%, #fff4 12.5%, #fff 25%)
	object-fit: cover
	border-bottom: 4px solid $brand-secondary

.wrapper
	position: relative
	min-height: 100vh

h1, h2, h3, h4, h5, h6,
span, p, li, label, strong,
img, svg,
.navbar-brand, .nav-link
	user-select: none

.copy
	user-select: text
	
img, svg
	pointer-events: none

img
	width: 100%

h1, h2, h3, h4, h5, h6
	font-weight: 900

p
	color: $brand-secondary