.slider
	margin-block-start: $gap-xl
	margin-inline: auto
	border-radius: $border-radius
	max-height: 400px
	max-width: 1200px
	overflow: hidden
	.slider-image
		width: 400px
		height: 100%
		max-height: 400px
		object-fit: cover
		object-position: center

.slick-dots
	li
		button
			&:before
				color: $brand !important