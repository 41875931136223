#menu
	max-width: 900px
	margin-inline: auto

	.title,
	.subtitle
		text-align: center
	.card-menu
		@include grid(2, $gap-lg)

	.menu-list
		width: 100%
		max-width: 400px
		.menu-item
			display: flex
			flex-direction: column
			.menu-item-name
				display: flex
				justify-content: space-between
				width: 100%

		small
			padding-inline-start: $gap
			color: $brand-secondary