.leaflet-popup-content-wrapper,
.leaflet-popup-tip
	background: $body-bg2
	color: $white
	box-shadow: 0 3px 14px rgba(0,0,0,0.4)
	font-family: 'Poppins', sans-serif
	font-weight: 600

.leaflet-touch
	.leaflet-control-layers,
	.leaflet-bar
		border: unset

	.leaflet-bar
		a
			color: $white
			background-color: $body-bg2
			border-bottom: unset
			&:hover
				background-color: $body-bg
				color: $brand

		a:first-child
			border-top-left-radius: $border-radius
			border-top-right-radius: $border-radius
		a:last-child
			border-bottom-left-radius: $border-radius
			border-bottom-right-radius: $border-radius

.leaflet-control-attribution
	display: none

.leaflet-marker-popup
	display: flex
	place-items: center
	place-content: center
	flex-direction: column