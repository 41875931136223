footer
	position: relative
	display: flex
	flex-direction: column
	place-content: center
	place-items: center
	width: 100%
	bottom: 0
	min-height: 25vh
	margin-block-start: $gap-xl
	padding: $gap-lg
	background-color: $body-bg2

	.grid
		max-width: $width-content
		@include grid(3, $gap-lg)
		margin-block-end: unset

		.section
			display: flex
			place-items: center
			flex-direction: column
			strong
				font-size: 1.5em

			p,
			strong
				text-align: center

			.socials
				display: flex
				place-content: center
				gap: $gap
				margin-block-end: $gap
				svg
					font-size: 2em

	.copyright
		color: $secondary

	li,
	.nav-link
		svg
			color: $primary