@import 'bootstrap/scss/functions'

$brand: #AE8B33
$brand-secondary: #CC668D

// Bootstrap
$body-color: #fff
$body-bg: #231D20
$body-bg2: #171314
$min-contrast-ratio: 1.5
$enable-shadows: true
$enable-reduced-motion: false
$secondary: #64748B
$light: #f5f5f5
$primary: $brand
$info: #0EA5E9
$success: #10B981
$warning: #F97316
$danger: #F43F5E
$width-content: 1200px
$border-radius: .5rem

// Gaps & Padding
$gap-xs: .25rem
$gap-sm: .5rem
$gap: 1rem
$gap-lg: 3rem
$gap-xl: 5rem
$gap-icon: .5rem

// Required
@import 'bootstrap/scss/variables'
@import 'bootstrap/scss/mixins'
@import 'bootstrap/scss/root'
@import 'bootstrap/scss/bootstrap'

$bg-complement: $accordion-button-active-bg
$white-25: transparentize($white, .75)
$white-75: transparentize($white, .25)
$black-25: transparentize($black, .75)